import '../css/App.css';
import '../css/accountState.css';
import React, {useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DynamicVideo from './dynamicVideo';
import {Login} from './login'
import { datadogRum } from '@datadog/browser-rum';

document.addEventListener('contextmenu', event => event.preventDefault());

export function App() {

    const [token, setToken] = useState()
    if (!token) {
        return (
            <Login setToken={setToken} />
        );
    }
    else {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<DynamicVideo />} />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );

    }
}

if (process.env.REACT_APP_ENV !== 'development') {
    datadogRum.init({
        applicationId: 'f7a4e877-163c-4a46-a7ff-7b266284d06d',
        clientToken: 'pubba1428c1f03b5d6c22cb03a98bb99dc0',
        site: 'datadoghq.com',
        service: 'APAP',
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
    })

    datadogRum.startSessionReplayRecording();
};

