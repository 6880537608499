import '@fontsource/roboto/500.css'
import '../css/dynamicVideo.css'
import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import VideoPlayer from './videoPlayer'
import OptionsList from './optionsList'
import { nodes } from './nodes'
import Chip from '@mui/material/Chip'
import { SatisfactionSurvey } from './satisfactionSurvey'
import SpeechRecognition from './speechRecognition'
import IconsBar from './IconsBar'
import Cookies from 'universal-cookie'
import NavMenu from './navMenu'
import { v4 as uuidv4 } from 'uuid'
import { Faqs } from './faqs'
import { Updates } from './updates'
import {UserLogin}  from './UserLogin'
import  MicControl  from './micControl'
import { Howl } from 'howler'
import { Backdrop, Card, Button } from '@mui/material'
import { shortTerms } from './terms'
import { AccountSettings } from './accountSettings'


const Finalizar = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/62.%20Finalizar_x264.mp4'
const cookies = new Cookies()
const DATE = new Date()
const COOKIES_EXPIRE = new Date('12-01-' + (DATE.getFullYear() + 1))
const DOMAIN = process.env.REACT_APP_DOMAIN

class DynamicVideo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            video: nodes[0].video,
            ended: false,
            finish: false,
            pause: false,
            listening: false,
            update: false,
            node: 0,
            updateNode: {},
            welcomeVideo: '',
            conversational: false,
            hasDisplayTrialEnd: false,
            displayTrialMessage: false,
            token: null,
            trial: 0,
            sessionId: null,
            sound: new Howl({ html5: true, src: 'https://codeskulptor-demos.commondatastorage.googleapis.com/pang/arrow.mp3' }),
        }
        this.videoIndex = this.videoIndex.bind(this)
        this.displayOptions = this.displayOptions.bind(this)
        this.close = this.close.bind(this)
        this.finish = this.finish.bind(this)
        this.hide = this.hide.bind(this)
        this.back = this.back.bind(this)
        this.pauseVideo = this.pauseVideo.bind(this)
        this.actualNode = this.actualNode.bind(this)
        this.startSpeechListening = this.startSpeechListening.bind(this)
        this.stopSpeechListening = this.stopSpeechListening.bind(this)
        this.loadNode = this.loadNode.bind(this)
        this.goHome = this.goHome.bind(this)
        this.switchMode = this.switchMode.bind(this)
        this.setToken = this.setToken.bind(this)
        this.setId = this.setId.bind(this)
        this.logout = this.logout.bind(this)
        this.share = this.share.bind(this)
        this.displayPage = this.displayPage.bind(this)
        this.setSound = this.setSound.bind(this)
        this.trialCount = this.trialCount.bind(this)
    }


    componentDidMount() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const is_notification = urlParams.get('notification');


        var token = cookies.get('token')

        if (token !== '' && token !== undefined && token !== null) {
            this.setToken(token)
            this.setState({ conversational: true })
        }

        if (!this.state.sessionId) {
            let sessionId = uuidv4()
            this.setState({ sessionId: sessionId })
        }

        var date = new Date().getHours()
        const element = ReactDOM.findDOMNode(this)
        this.setState({ element: element })


    }

    /// function to handle right click to desible options
    handleContextMenu = (e) => {
        e.preventDefault()
    }


    componentDidUpdate() {


        if (this.state.conversational || !this.state.finish) {
            const element = ReactDOM.findDOMNode(this)
            if (element) {
                var optionList = element.querySelector('.list')
                if (optionList) {
                    optionList.setAttribute("hidden", true)
                }
            }

        }

    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick)
        document.removeEventListener("contextmenu", this.handleContextMenu)
    }


    switchMode() {
        if (!this.state.conversational) {
            this.setState({ conversational: true })
        }
        if (this.state.conversational) {
            this.setState({ conversational: false })
            // this.videoIndex({ video: nodes[0].video })
        }
        this.setState({ selectedPage: null })
    }

    setToken(token) {
        this.setState({ token: token })
    }

    setId(id) {
        this.setState({ id: id })
    }

    handleAccepTerms() {
        this.setState({ displayTerms: false })
        this.setState({ hasAcceptedTerms: true })
        this.setState({ conversational: true })
    }

    render() {
        const pages = {
            'faqs': <Faqs />,
            'updates': <Updates />,
            'account': <AccountSettings auth_token={this.state.token} />,
        }

        const card = <div style={{ padding: '6%' }}>
            <h3>Regístrate o Inicia Sesión</h3>
            <div style={{ marginTop: '20px', maxHeight: '38vh', overflow: 'scroll' }}>
                <p>{'Has alcanzado el límite de preguntas. Regístrate para seguir utilizando a LISA.'}</p>
            </div>
            <Button style={{ marginTop: '20px' }} onClick={() => this.setState({ displayTrialMessage: false })}>Cerrar</Button>
        </div>

        const cardTerms = <div style={{ padding: '6%' }}>
            <h3>Términos y Condiciones</h3>
            <div style={{ marginTop: '20px', height: '38vh', overflow: 'scroll' }}>
                {shortTerms}
            </div>
            <Button style={{ marginTop: '20px' }} onClick={() => this.handleAccepTerms()}>Aceptar</Button>
        </div>

        const video = this.state.video
        const element = this.state.element
        const closeLabel = <span>Finalizar <span className='icon'>X</span ></span>
        let micControl = ''
        let page = ''
        if (this.state.conversational) {
            micControl = <MicControl startSpeechListening={this.startSpeechListening} stopSpeechListening={this.stopSpeechListening} listeningOn={this.state.listening} />
        }

        if (this.state.selectedPage) {
            let close = <span style={{ color: 'black' }}>X</span >
            page = <div style={{ position: 'absolute', zIndex: 11000, width: "100%" }}>
                <Chip className="close-audio" label={close} variant="outlined" onClick={() => this.setState({ selectedPage: false })} style={{ zIndex: 10000, position: 'absolute' }} />
                {this.state.conversational && <NavMenu goHome={this.goHome}
                    trial={this.state.trial >= 5 ? false : true}
                    token={this.state.token}
                    login={this.switchMode}
                    isConversational={this.state.conversational}
                    logout={this.logout}
                    share={this.share}
                    selectPage={this.displayPage} />}
                {pages[this.state.selectedPage]}
            </div>
        }

        if (this.state.token === null && this.state.conversational) {
            return (
                <React.Fragment>
                    <Backdrop
                        style={{ zIndex: 10 }}
                        open={this.state.displayTrialMessage}>
                        <Card className='terms' style={{ marginTop: '30%', maxHeight: '65%', width: '90%', maxWidth: '450px' }}
                            variant="outlined">
                            {card}
                        </Card>
                    </Backdrop>
                    <UserLogin setToken={this.setToken} setId={this.setId} backButton={this.switchMode} />
                </React.Fragment>
            )
        }

        return (
            <div>
                {/* {page} */}
                <Backdrop
                    style={{ zIndex: 1000 }}
                    open={this.state.displayTerms}>
                    <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => this.setState({ displayTerms: false })} style={{ zIndex: 10000 }}> </Chip>
                    <Card className='terms' style={{ marginTop: '30%', height: '65%', width: '90%' }}
                        variant="outlined">
                        {cardTerms}
                    </Card>
                </Backdrop>
                {this.state.conversational ? <NavMenu goHome={this.goHome}
                    trial={this.state.trial >= 5 ? false : true}
                    token={this.state.token}
                    login={this.switchMode}
                    isConversational={this.state.conversational}
                    logout={this.logout}
                    share={this.share}
                    selectPage={this.displayPage} /> : <IconsBar shareElement={this.state.share} setShare={this.share} switchMode={this.switchMode} />}

                <SatisfactionSurvey ended={this.state.ended} back={this.back} hide={this.hide} />
                <Chip className="close" label={closeLabel} variant="outlined" onClick={() => this.close()} > </Chip>
                {/* <SpeechRecognition
                    actualNode={this.state.node}
                    videoIndex={this.videoIndex}
                    loadNode={this.loadNode}
                    listeningOn={this.state.listening}
                    startSpeechListening={this.startSpeechListening}
                    stopSpeechListening={this.stopSpeechListening}
                    id={this.state.id}
                    auth_token={this.state.token}
                    sessionId={this.state.sessionId}
                    close={this.close}
                    playVideo={this.pla}
                    sound={this.state.sound}
                    setSound={this.setSound}
                    trialCount={this.trialCount}
                    conversational={this.state.conversational} /> */}

                <OptionsList
                    className="options-list" close={this.close}
                    videoIndex={this.videoIndex}
                    domelement={element}
                    update={this.state.update}
                    actualNode={this.actualNode}
                    pause={this.pauseVideo}
                    updateNode={this.state.updateNode}
                    switchMode={this.switchMode}
                />
                <VideoPlayer showIcon={this.state.conversational}
                    className="videoPlayer1"
                    url={video}
                    ended={this.finish}
                    displayOptions={this.displayOptions}
                    videoIndex={this.videoIndex}
                    pause={this.state.pause}
                    listeningOn={this.state.listening}
                />
                {/* {micControl} */}
            </div>
        )


    }

    videoIndex(values) {
        this.setState({ listening: false })
        this.setState(values)
        this.setState({ update: false })
        this.setState({ SwipeAnimation: false })
    }

    loadNode(values) {
        this.setState({ update: true })
        this.setState({ updateNode: values })
    }

    finish(value) {

        if (value === true && this.state.finish === true) {
            this.setState({ ended: true })
        }
    }

    close() {
        if (!this.state.conversational) {
            var optionList = this.state.element.querySelector('.list')
            if (optionList) {
                optionList.setAttribute("hidden", true)
            }

        }
        var closeButton = this.state.element.querySelector('.close')
        closeButton.setAttribute("hidden", true)
        this.setState({ finish: true })
        this.setState({ ended: false })
        this.setState({ video: Finalizar })
        this.setState({ conversational: false })
    }

    back() {
        this.hide()
        window.location.reload()
    }

    hide() {
        this.setState({ ended: false })
        this.setState({ pause: false })
        this.setState({ finish: false })
    }

    displayOptions() {
        let element = ReactDOM.findDOMNode(this)
        if (!this.state.finish && !this.state.conversational) {
            var optionList = element.querySelector('.list')
            if (optionList) {
                optionList.removeAttribute("hidden")
            }

        }
    }
    actualNode(values) {
        this.setState({ node: values })
        if (values === 4) {
            cookies.set('afiliado', true, {
                path: '/', expires: COOKIES_EXPIRE,
                sameSite: 'none',
                secure: true,
                httpOnly: false,
                domain: DOMAIN
            })

        }
        if (values === 2) {
            cookies.set('visitante', true, {
                path: '/', expires: COOKIES_EXPIRE,
                sameSite: 'none',
                secure: true,
                httpOnly: false,
                domain: DOMAIN
            })
        }
    }

    pauseVideo() {
        this.setState({ pause: true })
    }

    startSpeechListening() {
        if (!this.state.listening) {
            this.setState({ listening: true })
        }
    }

    stopSpeechListening() {
        if (this.state.listening) {
            this.setState({ listening: false })
        }
    }

    goHome() {
        cookies.set('afiliado', false, {
            path: '/', expires: COOKIES_EXPIRE,
            sameSite: 'none',
            secure: true,
            httpOnly: false,
            domain: DOMAIN
        })
        cookies.set('visitante', false, {
            path: '/', expires: COOKIES_EXPIRE,
            sameSite: 'none',
            secure: true,
            httpOnly: false,
            domain: DOMAIN
        })
        this.videoIndex({ video: nodes[0].video })
        this.loadNode(nodes[0])
        this.actualNode(0)
        this.switchMode()
        this.setState({ selectedPage: null })
    }

    logout() {
        cookies.set('token', '', {
            path: '/', expires: COOKIES_EXPIRE,
            sameSite: 'none',
            secure: true,
            httpOnly: false,
            domain: DOMAIN
        })

        this.setState({ token: null })
        this.videoIndex({ video: nodes[0].video })
    }

    trialCount() {
        var count = cookies.get('Tq5') ? cookies.get('Tq5') : this.state.trial
        count = parseInt(count) + 1
        if (count >= 5 && !this.state.token) {
            return false
        }
        this.setState({ trial: count })
        cookies.set('Tq5', count, {
            path: '/', expires: COOKIES_EXPIRE,
            sameSite: 'none',
            secure: true,
            httpOnly: false,
            domain: DOMAIN
        })
        return true
    }

    share(element) {
        this.setState({ share: element })
    }

    displayPage(page) {
        this.setState({ selectedPage: page })
    }

    setSound(link) {
        this.setState({ sound: new Howl({ html5: true, src: link }) })
        this.state.sound.play()
    }

}

export default DynamicVideo