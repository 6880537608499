import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/authenticate/'
const USER_INFO = {
  error: false,
  isAuthenticated: false,
  success: false,
  id: null,
  token: null,
}

/**
 * authenticate the user
 */
export default function Authenticate() {
  const [userInfo, setUserInfo] = useState(USER_INFO)

  const authenticateUser = async (email, password) => {
    const requestInfo = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, password: password })
    };
    setUserInfo({ ...USER_INFO, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setUserInfo({
            isAuthenticated: result.authenticated,
            id: result.id,
            success: true,
            token: result.token,
          })
        } else {
          setUserInfo({
            error: result.error
          })
        }
      })
      .catch(error => {

        setUserInfo(c => ({ ...c, error }))
      })
  }
  return { ...userInfo, authenticateUser }
}
