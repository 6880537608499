

let Intro = ''
const Bavaro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Bvaro_x264.mp4'
const CSUCCOndiciones = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Condiciones_x264.mp4'
const CSUCProteccionFinanciera = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/CSUC%20Proteccin%20Financiera%20TC%20+%20Vida_x264.mp4'
const CSUCVolververProteccion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/CSUC%20Volver%20-%20Proteccin%20Financiera%20TC%20+%20Vida_x264.mp4'
const ComoProtejoMisFinanzas ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Cmo%20protejo%20mis%20finanzas__x264.mp4'
const ComoSolicitoCobertura = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Cmo%20solicito%20una%20cobertura__x264.mp4'
const DondeEstamos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Dnde%20estamos__x264.mp4'
const EmpleadoPublico = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Empleado%20pblico%20_%20militar_x264.mp4'
const EmpleadoPrivado = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Empleado%20privado%20_%20Trabajador%20independiente_x264.mp4'
const PublicoMilitarBlack = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Pblico%20_%20Militar%20-%20BLACK_x264.mp4'
const PublicoMilitarClasicaLocal = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Pblico%20_%20Militar%20-%20CLASICA%20LOCAL_x264.mp4'
const PublicoLocalClasica = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Pblico%20_%20Militar%20-%20CLASICA_x264.mp4'
const PublicoMilitarGold = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Pblico%20_%20Militar%20-%20GOLD_x264.mp4'
const PublicoMilitarInfinite = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Pblico%20_%20Militar%20-%20INFINITE_x264.mp4'
const PublicoMilitarPlatinum = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Pblico%20_%20Militar%20-%20PLATINUM_x264.mp4'
const Planes = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Planes_x264.mp4'
const PorqueEsNecesario = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Por%20qu%20es%20necesario__x264.mp4'
const PrivadoIndependienteBlack = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Privado%20_%20Independiente%20-%20BLACK_x264.mp4'
const PrivadoIndependienteClasicaLocal = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Privado%20_%20Independiente%20-%20CLASICA%20LOCAL_x264.mp4'
const PrivadoIndependienteClasica = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Privado%20_%20Independiente%20-%20CLASICA_x264.mp4'
const PrivadoIndependienteGold = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Privado%20_%20Independiente%20-%20GOLD_x264.mp4'
const PrivadoIndependienteInfinite = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Privado%20_%20Independiente%20-%20INFINITE_x264.mp4'
const PrivadoIndependientePlatinum = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Privado%20_%20Independiente%20-%20PLATINUM_x264.mp4'
const ProteccionFinanciera = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Proteccin%20Financiera%20TC%20+%20Vida_x264.mp4'
const QueAseguradoraProvee ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Qu%20aseguradora%20provee%20estos%20servicios__x264.mp4'
const Requisitos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Requisitos_x264.mp4'
const Santiago = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Santiago_x264.mp4'
const SantoDomingo = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Santo%20Domingo_x264.mp4'
const Ventajas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Ventajas_x264.mp4'
const VolverComoProtejoMisFinanzas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Cmo%20protejo%20mis%20finanzas__x264.mp4'
const VolverComoSolicitoCobertura = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Cmo%20solicito%20una%20cobertura__x264.mp4'
const VolverDondeEstamos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Dnde%20estamos__x264.mp4'
const VolverCondiciones = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Condiciones_x264.mp4'
const VolverEmpleado = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20(a%20Proteccin%20a%20Tarjeta%20de%20Crdito)_x264.mp4'
const VolverPlanes = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Planes_x264.mp4'
const VolverPorqueEsNecesario = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Por%20qu%20es%20necesario__x264.mp4'
const VolverQueaseguradora = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Qu%20aseguradora%20provee%20estos%20servicios__x264.mp4'
const VolverRequisitos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Requisitos_x264.mp4'
const VolverSSB = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Santo%20Domingo,%20Santiago%20y%20Bvaro_x264.mp4'
const VolverVentajas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Ventajas_x264.mp4'
const VolverQuienesSomos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Quines%20somos__x264.mp4'
const Fallecimiento = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Fallecimiento%20(Saldo%20Deudor)%20(1)_x264.mp4'
const Incapacidad = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4'
const VerOtrosPlanes = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Ver%20otros%20planes_x264.mp4'
const VolverDesempleo = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Desempleo_x264.mp4'
const VolverFallecimiento = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Fallecimiento%20(Saldo%20Deudor)_x264.mp4'
const VolverIncapacidad = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4'
const VolverNoposeoTarjeta = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20No%20poseo%20tarjeta%20de%20crdito_x264.mp4'
const Desempleo = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Desempleo_x264.mp4'
const NoPoseoTarjeta = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/No%20poseo%20tarjeta%20de%20crdito_x264.mp4'
const SolicitarEstaCobertura = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Solicitar%20cobertura_x264.mp4'
const SolicitarEstaProteccion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Solicitar%20esta%20proteccin_x264.mp4'
const VolverSolicitarEstaCobertura = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Solicitar%20cobertura_x264.mp4'
const VolverSolicitarEstaProteccion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4'
const VerOtrosCasos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Ver%20otros%20casos_x264.mp4'
const VolverEmpleadoPublicoPrivado ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Empleado%20privado%20_%20Trabajador%20independiente,%20Empleado%20pblico%20_%20militar_x264.mp4'
const QuienesSomos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Quines%20somos__x264.mp4'
const VolverProteccionTarjeta = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/newavatar/Volver%20-%20Proteccin%20a%20Tarjeta%20de%20Crdito_x264.mp4'


const CPF_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/1.%20Cmo%20protejo%20financieramente%20a%20mi%20familia__x264.mp4'
const PTC_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/2.%20Proteccin%20a%20Tarjeta%20de%20Crdito_x264.mp4'
const VT_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/3.%20Ventajas_x264.mp4'
const V_VT_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/4.%20Volver%20-%20Ventajas_x264.mp4'
const PEN_5 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/5.%20Por%20qu%20es%20necesario__x264.mp4'
const V_PEN_6 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/6.%20Volver%20-%20Por%20qu%20es%20necesario__x264.mp4'
const PL_7 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/7.%20Planes_x264.mp4'
const _8 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/8.%20(Empleado%20privado)%20&%20(Trabajador%20independiente)_x264.mp4'
const PICL_9 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/9.%20Privado%20_%20Independiente%20-%20CLASICA%20LOCAL_x264.mp4'
const PIC_10 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/10.%20Privado%20_%20Independiente%20-%20CLASICA_x264.mp4'
const PIG_11 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/11.%20Privado%20_%20Independiente%20-%20GOLD_x264.mp4'
const PIP_12 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/12.%20Privado%20_%20Independiente%20-%20PLATINUM_x264.mp4'
const PIB_13 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/13.%20Privado%20_%20Independiente%20-%20BLACK_x264.mp4'
const PII_14 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/14.%20Privado%20_%20Independiente%20-%20INFINITE_x264.mp4'
const PINT_15 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/15.%20Privado%20_%20Independiente%20-%20No%20poseo%20tarjeta%20de%20crdito_x264.mp4'
const V_PINT_16 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/16.%20Privado%20_%20Independiente%20-%20Volver%20-%20No%20poseo%20tarjeta%20de%20crdito_x264.mp4'
const PIVOP_17 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/17.%20Privado%20_%20Independiente%20-%20Ver%20otros%20planes_x264.mp4'
const V_PIPT_18 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/18.%20Privado%20_%20Independiente%20-%20Volver%20(a%20Proteccin%20a%20Tarjeta%20de%20Crdito)_x264.mp4'
const V_PIEP_19 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/19.%20Privado%20_%20Independiente%20-%20Volver%20-%20Empleado%20privado%20_%20Trabajador%20independiente_x264.mp4'
const _20 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/20.%20Empleado%20pblico%20_%20militar_x264.mp4'
const _21 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/21.%20Pblico%20_%20Militar%20-%20CLASICA%20LOCAL_x264.mp4'
const _22 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/22.%20Pblico%20_%20Militar%20-%20CLASICA_x264.mp4'
const _23 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/23.%20Pblico%20_%20Militar%20-%20GOLD_x264.mp4'
const _24 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/24.%20Pblico%20_%20Militar%20-%20PLATINUM_x264.mp4'
const _25 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/25.%20Pblico%20_%20Militar%20-%20BLACK_x264.mp4'
const _26 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/26.%20Pblico%20_%20Militar%20-%20INFINITE_x264.mp4'
const _27 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/27.%20Pblico%20_%20Militar%20-%20No%20poseo%20tarjeta%20de%20crdito_x264.mp4'
const _28 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/28.%20Pblico%20_%20Militar%20-%20Volver%20-%20No%20poseo%20tarjeta%20de%20crdito_x264.mp4'
const _29 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/29.%20Pblico%20_%20Militar%20-%20Ver%20otros%20planes_x264.mp4'
const _30 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/30.%20Pblico%20_%20Militar%20%20-%20Volver%20(a%20Proteccin%20a%20Tarjeta%20de%20Crdito)_x264.mp4'
const _31 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/31.%20Pblico%20_%20Militar%20-%20Volver%20-%20Empleado%20pblico%20_%20militar_x264.mp4'
const _32 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/32.%20Volver%20-%20Planes_x264.mp4'
const _33 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/33.%20Solicitar%20esta%20proteccin_x264.mp4'
const _34 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/34.%20Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4'
const _35 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/35.%20Volver%20-%20Proteccin%20a%20Tarjeta%20de%20Crdito_x264.mp4'
const _36 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/36.%20Volver%20-%20Cmo%20protejo%20financieramente%20a%20mi%20familia__x264.mp4'
const _37 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/37.%20Cmo%20solicito%20una%20cobertura__x264.mp4'
const _38 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/38.%20Cobertura%20-%20Proteccin%20a%20Tarjeta%20de%20Crdito_x264.mp4'
const _39 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/39.%20Cobertura%20-%20Condiciones_x264.mp4'
const _40 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/40.%20Cobertura%20-%20Volver%20-%20Condiciones_x264.mp4'
const _41 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/41.%20Cobertura%20-%20Requisitos_x264.mp4'
const _42 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/42.%20Cobertura%20-%20Desempleo_x264.mp4'
const _44 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/44.%20Cobertura%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4'
const _46 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/46.%20Cobertura%20-%20Fallecimiento%20(Saldo%20Deudor)_x264.mp4'
const _48 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/48.%20Cobertura%20-%20Ver%20otras%20coberturas_x264.mp4'
const _49 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/49.%20Cobertura%20-%20Volver%20-%20Desempleo_x264.mp4'
const _50 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/50.%20Cobertura%20-%20Volver%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4'
const _51 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/51.%20Cobertura%20-%20Volver%20-%20Fallecimiento%20(Saldo%20Deudor)_x264.mp4'
const _52 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/52.%20Cobertura%20-%20Volver%20-%20Requisitos_x264.mp4'
const _53 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/53.%20Cobertura%20-%20Solicitar%20cobertura_x264.mp4'
const _55 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/55.%20Cobertura%20-%20Volver%20-%20Solicitar%20cobertura_x264.mp4'
const _56 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/56.%20Cobertura%20-%20Volver%20-%20Proteccin%20a%20Tarjeta%20de%20Crdito_x264.mp4'
const _57 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/57.%20Cobertura%20-%20Volver%20-%20Cmo%20solicito%20una%20cobertura__x264.mp4'
const _58 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/58.%20Qu%20aseguradora%20provee%20estos%20servicios__x264.mp4'
const _59 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/59.%20Qu%20es%20Seguros%20Crecer__x264.mp4'
const _60 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/60.%20Volver%20-%20Qu%20es%20Seguros%20Crecer__x264.mp4'
const _62 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/62.%20Finalizar_x264.mp4'
const _61 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/61.%20Volver%20-%20Qu%20aseguradora%20provee%20estos%20servicios__x264.mp4'


//prestamo
const _63 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/63.%20Proteccin%20a%20Prstamo_x264.mp4'
const _64 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/64.%20Ventajas_x264.mp4'
const _65 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/65.%20Volver%20-%20Ventajas_x264.mp4'
const _66 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/66.%20Por%20qu%20es%20necesario__x264.mp4'
const _67 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/67.%20Volver%20-%20Por%20qu%20es%20necesario__x264.mp4'
const _68 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/68.%20Planes_x264.mp4'
const _69 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/69.%20Empleado%20privado%20_%20Trabajador%20independiente_x264.mp4'
const _70 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/70.%20Volver%20-%20Empleado%20privado%20_%20Trabajador%20independiente_x264.mp4'
const _71 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/71.%20Empleado%20pblico%20(1)_x264.mp4'
const _72 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/72.%20Volver%20-%20Empleado%20pblico_x264.mp4'
const _73 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/73.%20No%20poseo%20prstamo%20con%20APAP_x264.mp4'
const _74 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/74.%20Volver%20-%20No%20poseo%20prstamo%20con%20APAP_x264.mp4'
const _75 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/75.%20Volver%20-%20Planes_x264.mp4'
const _76 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/76.%20Principales%20Exclusiones_x264.mp4'
const _77 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/77.%20Volver%20-%20Principales%20Exclusiones%20(1)_x264.mp4'
const _78 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/78.%20Solicitar%20esta%20proteccin_x264.mp4'
const _79 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/79.%20Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4'
const _80 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/80.%20Volver%20-%20Proteccin%20a%20Prstamos_x264.mp4'
const _81 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/81.%20Volver%20-%20Requisitos_x264.mp4'
const _82 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/82.%20Proteccin%20a%20Prstamo_x264.mp4'
const _83 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/83.%20Condiciones_x264.mp4'
const _84 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/84.%20Volver%20-%20Condiciones_x264.mp4'
const _85 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/85.%20Requisitos_x264.mp4'
const _86 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/86.%20Desempleo_x264.mp4'
const _87 = ''
const _88 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/88.%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4'
const _89 = ''
const _90 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/90.%20Ver%20otras%20coberturas_x264.mp4'
const _91 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/91.%20Volver%20-%20Desempleo_x264.mp4'
const _92 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/92.%20Volver%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4'
const _93 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/93.%20Solicitar%20cobertura_x264.mp4'
const _94 = ''
const _95 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/95.%20Volver%20-%20Solicitar%20cobertura_x264.mp4'
const _96 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/prestamo/96.%20Volver%20-%20proteccin%20a%20prstamo_x264.mp4'


const _97 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/97.%20Proteccin%20a%20Accidentes%20Personales_x264.mp4';
const _98 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/98.%20Ventajas_x264.mp4';
const _99 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/99.%20Volver%20-%20Ventajas_x264.mp4';
const _100 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/100.%20Por%20qu%20es%20necesario__x264.mp4';
const _101 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/101.%20Volver%20-%20Por%20qu%20es%20necesario__x264.mp4';
const _102 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/102.%20Planes_x264.mp4';
const _103 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/103.%20Plan%20B_x264.mp4';
const _104 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/104.%20Volver%20-%20Plan%20B_x264.mp4';
const _105 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/105.%20Plan%20C_x264.mp4';
const _106 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/106.%20Volver%20-%20Plan%20C_x264.mp4';
const _107 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/107.%20Volver%20-%20Planes_x264.mp4';
const _108 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/108.%20Solicitar%20esta%20proteccin_x264.mp4';
const _109 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/109.%20Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4';
const _110 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/110.%20Volver%20-%20Proteccin%20a%20Accidentes%20Personales_x264.mp4';
const _111 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/111.%20Cobertura%20-%20Proteccin%20a%20Accidentes%20Personales_x264.mp4';
const _112 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/112.%20Cobertura%20-%20Condiciones_x264.mp4';
const _113 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/113.%20Cobertura%20-%20Volver%20-%20Condiciones_x264.mp4';
const _114 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/114.%20Cobertura%20-%20Requisitos_x264.mp4';
const _115 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/115.%20Cobertura%20-%20Fallecimiento%20Accidental_x264.mp4';
const _116 = '';
const _117 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/117.%20Cobertura%20-%20Desmembramiento%20causado%20por%20Accidente_x264.mp4';
const _118 = '';
const _119 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/119.%20Cobertura%20-%20Incapacidad%20Total%20Permanente%20causado%20por%20Accidente_x264.mp4';
const _120 = '';
const _121 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/121.%20Cobertura%20-%20Ver%20otras%20coberturas_x264.mp4';
const _122 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/122.%20Cobertura%20-%20Volver%20-%20Fallecimiento%20Accidental_x264.mp4';
const _123 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/123.%20Cobertura%20-%20Volver%20-%20Desmembramiento%20causado%20por%20Accidente_x264.mp4';
const _124 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/124.%20Cobertura%20-%20Volver%20-%20Incapacidad%20Total%20Permanente%20causado%20por%20Accidente_x264.mp4';
const _125 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/125.%20Cobertura%20-%20Volver%20-%20Requisitos_x264.mp4';
const _126 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/126.%20Cobertura%20-%20Solicitar%20cobertura_x264.mp4';
const _127 = '';
const _128 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/128.%20Cobertura%20-%20Volver%20-%20Solicitar%20cobertura_x264.mp4';
const _129 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/accidente-personales/129.%20Cobertura%20-Volver%20-%20Proteccin%20a%20Accidentes%20Personales_x264.mp4';


//exequial
const _130 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/130.%20Asistencia%20Exequial_x264.mp4'
const _131 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/131.%20Ventajas_x264.mp4'
const _132 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/132.%20Volver%20-%20Ventajas_x264_001.mp4'
const _133 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/133.%20Por%20qu%20es%20necesario__x264.mp4'
const _134 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/134.%20Volver%20-%20Por%20qu%20es%20necesario__x264.mp4'
const _135 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/135.%20Planes_x264.mp4'
const _136 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/136.%20Plan%20III_x264.mp4'
const _137 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/137.%20Plan%20III%20-%20Volver_x264.mp4'
const _138 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/138.%20Plan%20IV_x264.mp4'
const _139 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/139.%20Plan%20IV_x264.mp4'
const _140 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/140.%20Volver%20-%20Planes_x264.mp4'
const _141 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/141.%20Solicitar%20esta%20proteccin_x264.mp4'
const _142 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/142.%20Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4'
const _143 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/143.%20Volver%20-%20Asistencia%20Exequial_x264.mp4'
const _144 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/144.%20Cobertura%20-%20Asistencia%20Exequial_x264.mp4'
const _145 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/145.%20Cobertura%20-%20Condiciones_x264.mp4'
const _146 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/146.%20Cobertura%20-%20Volver%20-%20Condiciones_x264.mp4'
const _147 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/147.%20Cobertura%20-%20Requisitos_x264.mp4'
const _148 = ''
const _149 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/149.%20Cobertura%20-%20Volver%20-%20Requisitos_x264.mp4'
const _150 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/150.%20Cobertura%20-%20Solicitar%20cobertura_x264.mp4'
const _151 = ''
const _152 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/152.%20Cobertura%20-%20Volver%20-%20Solicitar%20cobertura_x264.mp4'
const _153 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/asistencia-exequial/153.%20Cobertura%20-%20Volver%20-%20Asistencia%20Exequial_x264.mp4'


const _154 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/154.%20Hogar%20Protegido_x264.mp4';
const _155 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/155.%20Ventajas_x264.mp4';
const _156 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/156.%20Volver%20-%20Ventajas_x264.mp4';
const _157 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/157.%20Por%20qu%20es%20necesario__x264.mp4';
const _158 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/158.%20Volver%20-%20Por%20qu%20es%20necesario__x264.mp4';
const _159 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/159.%20Planes_x264.mp4';
const _160 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/160.%20Cobertura%20No.%201_x264.mp4';
const _161 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/161.%20Cobertura%20No.%202_x264.mp4';
const _162 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/162.%20Cobertura%20No.%203_x264.mp4';
const _163 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/163.%20Cobertura%20No.%204_x264.mp4';
const _164 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/164.%20Volver%20-%20Cobertura%20No.%201,%202,%203%20y%204_x264.mp4';
const _165 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/165.%20Volver%20-%20Planes_x264.mp4';
const _166 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/166.%20Principales%20Exclusiones_x264.mp4';
const _167 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/167.%20Volver%20exclusiones_x264.mp4';
const _168 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/168.%20Solicitar%20esta%20proteccin_x264.mp4';
const _169 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/169.%20Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4';
const _170 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/170.%20Vover%20-%20Hogar%20Protegido_x264.mp4';
const _171 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/171.%20Cobertura%20-%20Hogar%20Protegido_x264.mp4';
const _172 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/172.%20Cobertura%20-%20Requisitos_x264.mp4';
const _173 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/173.%20Cobertura%20-%20Incendio_x264.mp4';
const _174 = '';
const _175 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/175.%20Cobertura%20-%20Robo%20con%20Violencia_x264.mp4';
const _176 = '';
const _177 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/177.%20Cobertura%20-%20Responsabilidad%20Civil_x264.mp4';
const _178 = '';
const _179 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/179.%20Cobertura%20-%20Gastos%20Mdicos%20por%20accidente_x264.mp4';
const _180 = '';
const _181 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/181.%20Cobertura%20-%20Muerte%20Accidental_x264.mp4';
const _182 = '';
const _183 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/183.%20Cobertura%20-%20Ver%20otras%20coberturas_x264.mp4';
const _184 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/184.%20Cobertura%20-%20Volver%20-%20Incendio_x264.mp4';
const _185 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/185.%20Cobertura%20-%20Volver%20-%20Robo%20con%20Violencia_x264.mp4';
const _186 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/186.%20Cobertura%20-%20Volver%20-%20Responsabilidad%20Civil_x264.mp4';
const _187 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/187.%20Cobertura%20-%20Volver%20-%20Gastos%20Mdicos%20por%20accidente_x264.mp4';
const _188 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/188.%20Cobertura%20-%20Volver%20-%20Muerte%20Accidental_x264.mp4';
const _189 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/189.%20Cobertura%20-%20Volver%20-%20Requisitos_x264.mp4';
const _190 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/190.%20Cobertura%20-%20Solicitar%20cobertura_x264.mp4';
const _191 = '';
const _192 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/192.%20Cobertura%20-%20Volver%20-%20Solicitar%20cobertura_x264.mp4';
const _193 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/hogar-protegido/193.%20Cobertura%20-%20Volver%20-%20Hogar%20Protegido_x264.mp4';




const _194 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/194.%20Canasta%20Protegida_x264.mp4';
const _195 = _183
const _196 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/196.%20Volver%20-%20Ventajas_x264.mp4';
const _197 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/197.%20Por%20qu%20es%20necesario__x264.mp4';
const _198 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/198.%20Volver%20-%20Por%20qu%20es%20necesario__x264.mp4';
const _199 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/199.%20Planes_x264.mp4';
const _200 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/200.%20Opcin%201_x264.mp4';
const _201 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/201.%20Opcin%202_x264.mp4';
const _202 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/202.%20Opcin%203_x264.mp4';
const _203 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/203.%20Volver%20-%20Opcin%201,%202%20y%203_x264.mp4';
const _204 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/204.%20Volver%20-%20Planes_x264.mp4';
const _205 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/205.%20Principales%20Exclusiones_x264.mp4';
const _206 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/206.%20Desempleo_x264.mp4';
const _207 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/207.%20Volver%20-%20Desempleo_x264.mp4';
const _208 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/208.%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4';
const _209 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/209.%20Volver%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4';
const _210 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/210.%20Volver%20-%20Principales%20Exclusiones_x264.mp4';
const _211 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/211.%20Solicitar%20esta%20proteccin_x264.mp4';
const _212 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/212.%20Volver%20-%20Solicitar%20esta%20proteccin_x264.mp4';
const _213 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/213.%20Volver%20-%20Proteccin%20a%20Tarjeta%20de%20Crdito_x264.mp4';
const _214 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/214.%20Cobertura%20-%20Canasta%20Protegida_x264.mp4';
const _215 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/215.%20Cobertura%20-%20Condiciones_x264.mp4';
const _216 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/216.%20Cobertura%20-%20Volver%20-%20Condiciones_x264.mp4';
const _217 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/217.%20Cobertura%20-%20Requisitos_x264.mp4';
const _218 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/218.%20Cobertura%20-%20Desempleo_x264.mp4';
const _219 = '';
const _220 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/220.%20Cobertura%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4';
const _221 = '';
const _222 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/222.%20Cobertura%20-%20Fallecimiento_x264.mp4';
const _223 = '';
const _224 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/224.%20Cobertura%20-%20Volver%20-%20Desempleo_x264.mp4';
const _225 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/225.%20Cobertura%20-%20Volver%20-%20Incapacidad%20Total%20Temporal%20por%20Accidente_x264.mp4';
const _226 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/226.%20Cobertura%20-%20Volver%20-%20Fallecimiento_x264.mp4';
const _227 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/227.%20Cobertura%20-%20Volver%20-%20Requisitos_x264.mp4';
const _228 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/228.%20Cobertura%20-%20Solicitar%20cobertura_x264.mp4';
const _229 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/229.%20Cobertura%20-%20Volver%20-%20Solicitar%20cobertura_x264.mp4';
const _230 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/canasta-protegida/230.%20Cobertura%20-%20Volver%20-%20Canasta%20Protegida_x264.mp4';


var date = new Date().getHours()

// good morning video
if (date >= 5) {
    Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/Buenos%20das%20(1)_x264.mp4' 
}
// good afternoon video
if (date >= 12) { Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/Buenas%20tardes_x264.mp4' }
// good night video
if (date > 18 || date < 5) { Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/final/tarjeta-de-creditos/Buenas%20noches%20(2)_x264.mp4'}


export const nodes = [
    {
        id: 0,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 1,
                videoUrl: CPF_1,
                text: '¿Cómo protejo financieramente a mi familia?',
            },
            {
                type: 'node',
                videoUrl: _37,
                text: '¿Cómo solicito una cobertura?',
                nextNodeId: 2,
            },
            {
                type: 'node',
                videoUrl: _58,
                text: '¿Qué aseguradora provee estos servicios?',
                nextNodeId: 3,
            }  
        ],
        video: Intro,
    },
    {
        id: 1,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: PTC_2,
                text: 'Protección a Tarjeta de Crédito',
                nextNodeId: 4,
            },
            {
                type: 'node',
                videoUrl: _63,
                text: 'Protección a Préstamo',
                nextNodeId: 27,
            },
            {
                type: 'node',
                videoUrl: _97,
                text: 'Protección a Accidentes Personales',
                nextNodeId: 44,
            },
            {
                type: 'node',
                videoUrl: _130,
                text: 'Asistencia Exequial',
                nextNodeId: 51,
            },
            {
                type: 'node',
                videoUrl: _154,
                text: 'Hogar Protegido',
                nextNodeId: 58,
            },
            {
                type: 'node',
                videoUrl: _194,
                text: 'Canasta Protegida',
                nextNodeId: 65,
            },
            {
                type: 'node',
                videoUrl: _36,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 2,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _38,
                text: 'Protección a Tarjeta de Crédito',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: _82,
                text: 'Protección a Préstamo',
                nextNodeId: 38,
            },
            {
                type: 'node',
                videoUrl: _111,
                text: 'Protección a Accidentes Personales',
                nextNodeId: 74,
            },
            {
                type: 'node',
                videoUrl: _144,
                text: 'Asistencia Exequial',
                nextNodeId: 81,
            },
            {
                type: 'node',
                videoUrl: _171,
                text: 'Hogar Protegido',
                nextNodeId: 85,
            },
            {
                type: 'node',
                videoUrl: _214,
                text: 'Canasta Protegida',
                nextNodeId: 96,
            },
            {
                type: 'node',
                videoUrl: _57,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 3,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _59,
                text: '¿Qué es Seguros Crecer?',
                nextNodeId: 6,
            },
            // {
            //     type: 'node',
            //     videoUrl: DondeEstamos,
            //     text: '¿Dónde están ubicados?',
            //     nextNodeId: 7,
            // },  
            {
                type: 'node',
                videoUrl: _61,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 4,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VT_3,
                text: 'Ventajas',
                nextNodeId: 8,
            },
            {
                type: 'node',
                videoUrl: PEN_5,
                text: '¿Por qué es necesario?',
                nextNodeId: 9,
            },
            {
                type: 'node',
                videoUrl: PL_7,
                text: 'Planes',
                nextNodeId: 10,
            },
            {
                type: 'node',
                videoUrl: _33,
                text: 'Solicitar esta protección',
                nextNodeId: 11,
            },
            {
                type: 'node',
                videoUrl: _35,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 5,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _39,
                text: 'Condiciones',
                nextNodeId: 12,
            },
            {
                type: 'node',
                videoUrl: _41,
                text: 'Requisitos',
                nextNodeId: 13,
            },
            {
                type: 'node',
                videoUrl: _53,
                text: 'Solicitar cobertura',
                nextNodeId: 26,
            },
            {
                type: 'node',
                videoUrl: _56 ,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 6,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _60,
                text: 'Volver',
                nextNodeId: 3,
            }
        ],
    },
    {
        id: 7,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: SantoDomingo,
                text: 'Santo Domingo',
                nextNodeId: 14,
            },
            {
                type: 'node',
                videoUrl: Santiago,
                text: 'Santiago',
                nextNodeId: 15,
            },
            {
                type: 'node',
                videoUrl: Bavaro,
                text: 'Bávaro',
                nextNodeId: 16,
            },
            {
                type: 'node',
                videoUrl: VolverDondeEstamos,
                text: 'Volver',
                nextNodeId: 3,
            }
        ],
    },
    {
        id: 8,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: V_VT_4,
                text: 'Volver',
                nextNodeId: 4,
            }
        ],
    },
    {
        id: 9,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: V_PEN_6,
                text: 'Volver',
                nextNodeId: 4,
            }
        ],
    },
    {
        id: 10,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _8,
                text: 'Empleado privado / Trabajador independiente',
                nextNodeId: 17,
            },
            {
                type: 'node',
                videoUrl: _20,
                text: 'Empleado público / militar',
                nextNodeId: 18,
            },
            {
                type: 'node',
                videoUrl: _32,
                text: 'Volver',
                nextNodeId: 4,
            }
        ],
    },
    {
        id: 11,
        title: '',
        inputs: [],
        buttons: [
            // {
            //     type: 'link',
            //     url: 'tel:8093312727,2',
            //     text: 'De acuerdo',
            //     nextNodeId: 4,
            // },
            {
                type: 'node',
                videoUrl: _34,
                text: 'Volver',
                nextNodeId: 4,
            }
        ],
    },
    {
        id: 12,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _40,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 13,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _42,
                text: 'Desempleo',
                nextNodeId: 23,
            },
            {
                type: 'node',
                videoUrl: _44,
                text: 'Incapacidad Total Temporal por Accidente',
                nextNodeId: 24,
            },
            {
                type: 'node',
                videoUrl: _46,
                text: 'Fallecimiento (Saldo Deudor)',
                nextNodeId: 25,
            },
            {
                type: 'node',
                videoUrl: _52,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 14,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://www.google.com/maps/place/Banesco+Seguros/@18.4775174,-69.9366718,17z/data=!3m1!4b1!4m6!3m5!1s0x8eaf89e8d1c05b2d:0x484cc534416fe203!8m2!3d18.4775174!4d-69.9366718!16s%2Fg%2F11c250ynjb?hl=es-DO&entry=ttu',
                text: 'Ver en el mapa',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: VolverSSB,
                text: 'Volver',
                nextNodeId: 7,
            }
        ],
    },
    {
        id: 15,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://www.google.com/maps/place/Banesco+Juan+Pablo+Duarte+(Santiago)/@19.458315,-70.6768077,17z/data=!3m1!4b1!4m6!3m5!1s0x8eb1c586c19a3151:0x61da21eea1c2855!8m2!3d19.458315!4d-70.6768077!16s%2Fg%2F1ptyyt_23?hl=es-DO&entry=ttu',
                text: 'Ver en el mapa',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: VolverSSB,
                text: 'Volver',
                nextNodeId: 7,
            }
        ],
    },
    {
        id: 16,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://www.google.com/maps/place/C.+P.%C2%BA+San+Juan,+Punta+Cana+23000/@18.6400267,-68.3963063,17z/data=!3m1!4b1!4m6!3m5!1s0x8ea89331009e3d71:0x66dfe400c3a2249a!8m2!3d18.6400267!4d-68.3963063!16s%2Fg%2F11h54zlfk6?hl=es-DO&entry=ttu',
                text: 'Ver en el mapa',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: VolverSSB,
                text: 'Volver',
                nextNodeId: 7,
            }
        ],
    },
    {
        id: 17,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: PICL_9,
                text: 'CLASICA LOCAL',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: PIC_10,
                text: 'CLASICA',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: PIG_11,
                text: 'GOLD',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: PIP_12,
                text: 'PLATINUM',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: PIB_13,
                text: 'BLACK',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: PII_14,
                text: 'INFINITE',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: PINT_15,
                text: 'No poseo tarjeta de crédito',
                nextNodeId: 21,
            },
            {
                type: 'node',
                videoUrl: V_PIEP_19,
                text: 'Volver',
                nextNodeId: 10,
            }
        ],
    },
    {
        id: 18,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _21,
                text: 'CLASICA LOCAL',
                nextNodeId: 20,
            },
            {
                type: 'node',
                videoUrl: _22,
                text: 'CLASICA',
                nextNodeId: 20,
            },
            {
                type: 'node',
                videoUrl: _23,
                text: 'GOLD',
                nextNodeId: 20,
            },
            {
                type: 'node',
                videoUrl: _24,
                text: 'PLATINUM',
                nextNodeId: 20,
            },
            {
                type: 'node',
                videoUrl: _25,
                text: 'BLACK',
                nextNodeId: 20,
            },
            {
                type: 'node',
                videoUrl: _26,
                text: 'INFINITE',
                nextNodeId: 20,
            },
            {
                type: 'node',
                videoUrl: _27,
                text: 'No poseo tarjeta de crédito',
                nextNodeId: 22,
            },
            {
                type: 'node',
                videoUrl: _31,
                text: 'Volver',
                nextNodeId: 10,
            }
        ],
    },
    {
        id: 19,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: PIVOP_17,
                text: 'Ver otros planes',
                nextNodeId: 17,
            },
            {
                type: 'node',
                videoUrl: V_PIPT_18,
                text: 'Volver',
                nextNodeId: 4,
            }
        ],
    },
    {
        id: 20,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _29,
                text: 'Ver otros planes',
                nextNodeId: 18,
            },
            {
                type: 'node',
                videoUrl: _30,
                text: 'Volver',
                nextNodeId: 4,
            }
        ],
    },
    {
        id: 21,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: V_PINT_16,
                text: 'Volver',
                nextNodeId: 17,
            }
        ],
    },
    {
        id: 22,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _28,
                text: 'Volver',
                nextNodeId: 18,
            }
        ],
    },
    {
        id: 23,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista',
                nextNodeId: 5
            },
            {
                type: 'node',
                videoUrl: _48,
                text: 'Ver otros casos',
                nextNodeId: 13,
            },
            {
                type: 'node',
                videoUrl: _49,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 24,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/imcapacidad.png',
                text: 'Descargar lista',
                nextNodeId: 5
            },
            {
                type: 'node',
                videoUrl: _48,
                text: 'Ver otros casos',
                nextNodeId: 13,
            },
            {
                type: 'node',
                videoUrl: _50,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 25,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/fallecimiento.png',
                text: 'Descargar lista',
                nextNodeId: 5
            },
            {
                type: 'node',
                videoUrl: _48,
                text: 'Ver otros casos',
                nextNodeId: 13,
            },
            {
                type: 'node',
                videoUrl: _51,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 26,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'tel:8093312727,2',
                text: 'De acuerdo',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: _55,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 27,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _64,
                text: 'Ventajas',
                nextNodeId: 28,
            },
            {
                type: 'node',
                videoUrl: _66,
                text: '¿Por qué es necesario?',
                nextNodeId: 29,
            },
            {
                type: 'node',
                videoUrl: _68,
                text: 'Planes',
                nextNodeId: 30,
            },
            {
                type: 'node',
                videoUrl: _76,
                text: 'Principales exclusiones',
                nextNodeId: 94,
            },
            {
                type: 'node',
                videoUrl: _78,
                text: 'Solicitar esta protección',
                nextNodeId: 31,
            },
            {
                type: 'node',
                videoUrl: _80,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 28,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'node',
                videoUrl: _65,
                text: 'Volver',
                nextNodeId: 27,
            }
        ],
    },
    {
        id: 29,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'node',
                videoUrl: _67,
                text: 'Volver',
                nextNodeId: 27,
            }
        ],
    },
    {
        id: 30,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _69,
                text: 'Empleado privado / Trabajador independiente',
                nextNodeId: 32,
            },
            {
                type: 'node',
                videoUrl: _71,
                text: 'Empleado público / militar',
                nextNodeId: 33,
            },
            {
                type: 'node',
                videoUrl: _73,
                text: 'No poseo préstamo con APAP',
                nextNodeId: 95,
            },   
            {
                type: 'node',
                videoUrl: _75,
                text: 'Volver',
                nextNodeId: 27,
            }
        ],
    },
    {
        id: 31,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _79,
                text: 'Volver',
                nextNodeId: 27,
            }
        ],
    },
    {
        id: 32,
        title: '',
        inputs: [],
        buttons: [  
            {
                type: 'node',
                videoUrl: _70,
                text: 'Volver',
                nextNodeId: 30,
            }
        ],
    },
    {
        id: 33,
        title: '',
        inputs: [],
        buttons: [ 
            {
                type: 'node',
                videoUrl: _72,
                text: 'Volver',
                nextNodeId: 30,
            }
        ],
    },
    {
        id: 34,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverSolicitarEstaCobertura,
                text: 'Ver otra modalidad de pago',
                nextNodeId: 32,
            },  
            {
                type: 'node',
                videoUrl: VolverSolicitarEstaCobertura,
                text: 'Volver',
                nextNodeId: 27,
            }
        ],
    },
    {
        id: 35,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverSolicitarEstaCobertura,
                text: 'Volver',
                nextNodeId: 32,
            }
        ],
    },
    {
        id: 36,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverSolicitarEstaCobertura,
                text: 'Ver otra modalidad de pago',
                nextNodeId: 33,
            },  
            {
                type: 'node',
                videoUrl: VolverSolicitarEstaCobertura,
                text: 'Volver',
                nextNodeId: 27,
            }
        ],
    },
    {
        id: 37,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverSolicitarEstaCobertura,
                text: 'Volver',
                nextNodeId: 33,
            }
        ],
    },
    {
        id: 38,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _83,
                text: 'Condiciones',
                nextNodeId: 39,
            },
            {
                type: 'node',
                videoUrl: _85,
                text: 'Requisitos',
                nextNodeId: 40,
            },
            {
                type: 'node',
                videoUrl: _93,
                text: 'Solicitar cobertura',
                nextNodeId: 41,
            },
            {
                type: 'node',
                videoUrl: _96 ,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 39,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _84,
                text: 'Volver',
                nextNodeId: 38,
            }
        ],
    },
    {
        id: 40,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _86,
                text: 'Desempleo',
                nextNodeId: 42,
            },
            {
                type: 'node',
                videoUrl: _88,
                text: 'Incapacidad Total Temporal por Accidente',
                nextNodeId: 43,
            },
            {
                type: 'node',
                videoUrl: _52,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 41,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'tel:8093312727,2',
                text: 'De acuerdo',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: _95,
                text: 'Volver',
                nextNodeId: 38,
            }
        ],
    },
    {
        id: 42,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista',
                nextNodeId: 5
            },
            {
                type: 'node',
                videoUrl: _90,
                text: 'Ver otros casos',
                nextNodeId: 40,
            },
            {
                type: 'node',
                videoUrl: _91,
                text: 'Volver',
                nextNodeId: 38,
            }
        ],
    },
    {
        id: 43,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/imcapacidad.png',
                text: 'Descargar lista',
                nextNodeId: 5
            },
            {
                type: 'node',
                videoUrl: _90,
                text: 'Ver otros casos',
                nextNodeId: 40,
            },
            {
                type: 'node',
                videoUrl: _92,
                text: 'Volver',
                nextNodeId: 38,
            }
        ],
    },
    {
        id: 44,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _98,
                text: 'Ventajas',
                nextNodeId: 45,
            },
            {
                type: 'node',
                videoUrl: _100,
                text: '¿Por qué es necesario?',
                nextNodeId: 46,
            },
            {
                type: 'node',
                videoUrl: _102,
                text: 'Planes',
                nextNodeId: 47,
            },
            {
                type: 'node',
                videoUrl: _108,
                text: 'Solicitar esta protección',
                nextNodeId: 48,
            },
            {
                type: 'node',
                videoUrl: _110,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 45,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _99,
                text: 'Volver',
                nextNodeId: 44,
            }
        ],
    },
    {
        id: 46,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _101,
                text: 'Volver',
                nextNodeId: 44,
            }
        ],
    },
    {
        id: 47,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _103,
                text: 'Plan B',
                nextNodeId: 49,
            },
            {
                type: 'node',
                videoUrl: _105,
                text: 'Plan C',
                nextNodeId: 50,
            },
            {
                type: 'node',
                videoUrl: _107,
                text: 'Volver',
                nextNodeId: 44,
            }
        ],
    },
    {
        id: 48,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _109,
                text: 'Volver',
                nextNodeId: 44,
            }
        ],
    },
    {
        id: 49,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _104,
                text: 'Volver',
                nextNodeId: 47,
            }
        ],
    },
    {
        id: 50,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _106,
                text: 'Volver',
                nextNodeId: 47,
            }
        ],
    },
    {
        id: 51,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _131,
                text: 'Ventajas',
                nextNodeId: 52,
            },
            {
                type: 'node',
                videoUrl: _133,
                text: '¿Por qué es necesario?',
                nextNodeId: 53,
            },
            {
                type: 'node',
                videoUrl: _135,
                text: 'Planes',
                nextNodeId: 54,
            },
            {
                type: 'node',
                videoUrl: _141,
                text: 'Solicitar esta protección',
                nextNodeId: 55,
            },
            {
                type: 'node',
                videoUrl: _143,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 52,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _132,
                text: 'Volver',
                nextNodeId: 51,
            }
        ],
    },
    {
        id: 53,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _134,
                text: 'Volver',
                nextNodeId: 51,
            }
        ],
    },
    {
        id: 54,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _136,
                text: 'Plan III',
                nextNodeId: 56,
            },
            {
                type: 'node',
                videoUrl: _138,
                text: 'Plan IV',
                nextNodeId: 57,
            },
            {
                type: 'node',
                videoUrl: _140,
                text: 'Volver',
                nextNodeId: 51,
            }
        ],
    },
    {
        id: 55,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _142,
                text: 'Volver',
                nextNodeId: 51,
            }
        ],
    },
    {
        id: 56,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _137,
                text: 'Volver',
                nextNodeId: 54,
            }
        ],
    },
    {
        id: 57,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _139,
                text: 'Volver',
                nextNodeId: 54,
            }
        ],
    },
    {
        id: 58,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _155,
                text: 'Ventajas',
                nextNodeId: 59,
            },
            {
                type: 'node',
                videoUrl: _157,
                text: '¿Por qué es necesario?',
                nextNodeId: 60,
            },
            {
                type: 'node',
                videoUrl: _159,
                text: 'Planes',
                nextNodeId: 61,
            },
            {
                type: 'node',
                videoUrl: _166,
                text: 'Principales exclusiones',
                nextNodeId: 62,
            },
            {
                type: 'node',
                videoUrl: _168,
                text: 'Solicitar esta protección',
                nextNodeId: 63,
            },
            {
                type: 'node',
                videoUrl: _170,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 59,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _156,
                text: 'Volver',
                nextNodeId: 58,
            }
        ],
    },
    {
        id: 60,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _158,
                text: 'Volver',
                nextNodeId: 58,
            }
        ],
    },
    {
        id: 61,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _160,
                text: 'Cobertura No. 1',
                nextNodeId: 64,
            },
            {
                type: 'node',
                videoUrl: _161,
                text: 'Cobertura No. 2',
                nextNodeId: 64,
            },
            {
                type: 'node',
                videoUrl: _162,
                text: 'Cobertura No. 3',
                nextNodeId: 64,
            },
            {
                type: 'node',
                videoUrl: _163,
                text: 'Cobertura No. 4',
                nextNodeId: 64,
            },
            {
                type: 'node',
                videoUrl: _165,
                text: 'Volver',
                nextNodeId: 58,
            }
        ],
    },
    {
        id: 62,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _167,
                text: 'Volver',
                nextNodeId: 58,
            }
        ],
    },
    {
        id: 63,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _169,
                text: 'Volver',
                nextNodeId: 58,
            }
        ],
    },
    {
        id: 64,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _164,
                text: 'Volver',
                nextNodeId: 61,
            }
        ],
    },
    {
        id: 65,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _195,
                text: 'Ventajas',
                nextNodeId: 66,
            },
            {
                type: 'node',
                videoUrl: _197,
                text: '¿Por qué es necesario?',
                nextNodeId: 67,
            },
            {
                type: 'node',
                videoUrl: _199,
                text: 'Planes',
                nextNodeId: 68,
            },
            {
                type: 'node',
                videoUrl: _205,
                text: 'Principales exclusiones',
                nextNodeId: 69,
            },
            {
                type: 'node',
                videoUrl: _211,
                text: 'Solicitar esta protección',
                nextNodeId: 70,
            },
            {
                type: 'node',
                videoUrl: _213,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 66,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _196,
                text: 'Volver',
                nextNodeId: 65,
            }
        ],
    },
    {
        id: 67,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _198,
                text: 'Volver',
                nextNodeId: 65,
            }
        ],
    },
    {
        id: 68,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _200,
                text: 'Opción 1',
                nextNodeId: 71,
            },
            {
                type: 'node',
                videoUrl: _201,
                text: 'Opción 2',
                nextNodeId: 71,
            },
            {
                type: 'node',
                videoUrl: _202,
                text: 'Opción 3',
                nextNodeId: 71,
            },
            {
                type: 'node',
                videoUrl: _204,
                text: 'Volver',
                nextNodeId: 65,
            }
        ],
    },
    {
        id: 69,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _206,
                text: 'Desempleo',
                nextNodeId: 72,
            },
            {
                type: 'node',
                videoUrl: _208,
                text: 'Incapacidad Total Temporal por Accidente',
                nextNodeId: 73,
            },
            {
                type: 'node',
                videoUrl: _210,
                text: 'Volver',
                nextNodeId: 65,
            }
        ],
    },
    {
        id: 70,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _212,
                text: 'Volver',
                nextNodeId: 65,
            }
        ],
    },
    {
        id: 71,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _203,
                text: 'Volver',
                nextNodeId: 68,
            }
        ],
    },
    {
        id: 72,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _207,
                text: 'Volver',
                nextNodeId: 69,
            }
        ],
    },
    {
        id: 73,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _209,
                text: 'Volver',
                nextNodeId: 69,
            }
        ],
    },
    {
        id: 74,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _112,
                text: 'Condiciones',
                nextNodeId: 75,
            },
            {
                type: 'node',
                videoUrl: _114,
                text: 'Requisitos',
                nextNodeId: 76,
            },
            {
                type: 'node',
                videoUrl: _126,
                text: 'Solicitar cobertura',
                nextNodeId: 77,
            },
            {
                type: 'node',
                videoUrl: _129,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 75,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _113,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 76,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _115,
                text: 'Fallecimiento Accidental',
                nextNodeId: 78,
            },
            {
                type: 'node',
                videoUrl: _117,
                text: 'Desmembramiento causado por Accidente',
                nextNodeId: 79,
            },
            {
                type: 'node',
                videoUrl: _119,
                text: 'Incapacidad Total y Permanente',
                nextNodeId: 80,
            },
            {
                type: 'node',
                videoUrl: _125 ,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 77,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'tel:8093312727,2',
                text: 'De acuerdo',
                nextNodeId: 0,
            },
            {
                type: 'node',
                videoUrl: _128,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 78,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _121,
                text: 'Ver otras coberturas',
                nextNodeId: 76,
            },
            {
                type: 'node',
                videoUrl: _122,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 79,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _121,
                text: 'Ver otras coberturas',
                nextNodeId: 76,
            },
            {
                type: 'node',
                videoUrl: _123,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 80,
        title: '',
        inputs: [],
        buttons: [

            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _121,
                text: 'Ver otras coberturas',
                nextNodeId: 76,
            },
            {
                type: 'node',
                videoUrl: _124,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 81,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _145,
                text: 'Condiciones',
                nextNodeId: 82,
            },
            {
                type: 'node',
                videoUrl: _147,
                text: 'Requisitos',
                nextNodeId: 83,
            },
            {
                type: 'node',
                videoUrl: _150,
                text: 'Solicitar cobertura',
                nextNodeId: 84,
            },
            {
                type: 'node',
                videoUrl: _153 ,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 82,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _146,
                text: 'Volver',
                nextNodeId: 81,
            }
        ],
    },
    {
        id: 83,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _149,
                text: 'Volver',
                nextNodeId: 81,
            }
        ],
    },
    {
        id: 84,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'tel:8093312727,2',
                text: 'De acuerdo',
                nextNodeId: 0,
            },
            {
                type: 'node',
                videoUrl: _152,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 85,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _172,
                text: 'Requisitos',
                nextNodeId: 86,
            },
            {
                type: 'node',
                videoUrl: _190,
                text: 'Solicitar cobertura',
                nextNodeId: 87,
            },
            {
                type: 'node',
                videoUrl: _193 ,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 86,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _173,
                text: 'Incendio',
                nextNodeId: 88,
            },
            {
                type: 'node',
                videoUrl: _175,
                text: 'Robo con Violencia',
                nextNodeId: 89,
            },
            {
                type: 'node',
                videoUrl: _177,
                text: 'Responsabilidad Civil',
                nextNodeId: 90,
            },
            {
                type: 'node',
                videoUrl: _179,
                text: 'Gastos Médicos por accidente',
                nextNodeId: 91,
            },
            {
                type: 'node',
                videoUrl: _181,
                text: 'Muerte Accidental (doméstica)',
                nextNodeId: 92,
            },
            {
                type: 'node',
                videoUrl: _189 ,
                text: 'Volver',
                nextNodeId: 85,
            }
        ],
    },
    {
        id: 87,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'tel:8093312727,2',
                text: 'De acuerdo',
                nextNodeId: 0,
            },
            {
                type: 'node',
                videoUrl: _192,
                text: 'Volver',
                nextNodeId: 74,
            }
        ],
    },
    {
        id: 88,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _183,
                text: 'Ver otras coberturas',
                nextNodeId: 86,
            },
            {
                type: 'node',
                videoUrl: _184,
                text: 'Volver',
                nextNodeId: 85,
            }
        ],
    },
    {
        id: 89,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _183,
                text: 'Ver otras coberturas',
                nextNodeId: 86,
            },
            {
                type: 'node',
                videoUrl: _185,
                text: 'Volver',
                nextNodeId: 85,
            }
        ],
    },
    {
        id: 90,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _183,
                text: 'Ver otras coberturas',
                nextNodeId: 86,
            },
            {
                type: 'node',
                videoUrl: _186,
                text: 'Volver',
                nextNodeId: 85,
            }
        ],
    },
    {
        id: 91,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _183,
                text: 'Ver otras coberturas',
                nextNodeId: 86,
            },
            {
                type: 'node',
                videoUrl: _187,
                text: 'Volver',
                nextNodeId: 85,
            }
        ],
    },
    {
        id: 92,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _183,
                text: 'Ver otras coberturas',
                nextNodeId: 86,
            },
            {
                type: 'node',
                videoUrl: _188,
                text: 'Volver',
                nextNodeId: 85,
            }
        ],
    },
    {
        id: 93,
        title: '',
        inputs: [],
        buttons: [
        ],
    },
    {
    id: 94,
    title: '',
    inputs: [],
    buttons: [

        {
            type: 'node',
            videoUrl: _79,
            text: 'Volver',
            nextNodeId: 27,
        }
    ],
    },
    {
        id: 95,
        title: '',
        inputs: [],
        buttons: [
    
            {
                type: 'node',
                videoUrl: _74,
                text: 'Volver',
                nextNodeId: 30,
            }
        ],
    },
    {
        id: 96,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _215,
                text: 'Condiciones',
                nextNodeId: 97,
            },
            {
                type: 'node',
                videoUrl: _217,
                text: 'Requisitos',
                nextNodeId: 98,
            },
            {
                type: 'node',
                videoUrl: _228,
                text: 'Solicitar cobertura',
                nextNodeId: 99,
            },
            {
                type: 'node',
                videoUrl: _213 ,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 97,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _56 ,
                text: 'Volver',
                nextNodeId: 96,
            }
        ],
    },
    {
        id: 98,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: _218,
                text: 'Desempleo',
                nextNodeId: 100,
            },
            {
                type: 'node',
                videoUrl: _220,
                text: 'Incapacidad Total Temporal por Accidente',
                nextNodeId: 101,
            },
            {
                type: 'node',
                videoUrl: _222,
                text: 'Fallecimiento',
                nextNodeId: 102,
            },
            {
                type: 'node',
                videoUrl: _227 ,
                text: 'Volver',
                nextNodeId: 96,
            }
        ],
    },
    {
        id: 99,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'tel:8093312727,2',
                text: 'De acuerdo',
                nextNodeId: 0,
            },
            {
                type: 'node',
                videoUrl: _230,
                text: 'Volver',
                nextNodeId: 96,
            }
        ],
    },
    {
        id: 100,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _195,
                text: 'Ver otras coberturas',
                nextNodeId: 98,
            },
            {
                type: 'node',
                videoUrl: _224,
                text: 'Volver',
                nextNodeId: 96,
            }
        ],
    },
    {
        id: 101,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _195,
                text: 'Ver otras coberturas',
                nextNodeId: 98,
            },
            {
                type: 'node',
                videoUrl: _225,
                text: 'Volver',
                nextNodeId: 96,
            }
        ],
    },
    {
        id: 102,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'download',
                url: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/apap/desempleo.png',
                text: 'Descargar lista de requisitos',
                nextNodeId: 0
            },
            {
                type: 'node',
                videoUrl: _195,
                text: 'Ver otras coberturas',
                nextNodeId: 98,
            },
            {
                type: 'node',
                videoUrl: _226,
                text: 'Volver',
                nextNodeId: 96,
            }
        ],
    },
];