import '@fontsource/roboto/500.css';
import '../css/accountState.css';
import '../css/optionsList.css';
import React from 'react';
import PinInput from 'react-pin-input';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import RequirePasswordReset from '../hooks/requirePasswordReset';
import ResetPassword from '../hooks/resetPassword';
import { Stack } from '@mui/material'
import { FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { InputAdornment, IconButton } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import { Alert } from '@mui/material'


function PasswordReset({ closeView, displayAlert, setLoading }) {

    const [pin, setPin] = useState();
    const [email, setEmail] = useState()
    const [alert, setAlert] = useState()
    const [password, setPassword] = useState()
    const [confirmedPassword, setConfirmedPassword] = useState()
    const [showPassword, setShowPassword] = React.useState(false);


    const pinContainer = React.useRef();

    const {
        isLoading,
        code_requested,
        pin_confirmed,
        requirePasswordReset
    } = RequirePasswordReset()

    const {
        isNewPassSet,
        resetPassword
    } = ResetPassword()

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (isNewPassSet) {
            displayAlert('Se ha actualizado la contraseña')
            closeView(false)
        }
        if (isLoading) {
            setLoading(true)
        } else (
            setLoading(false)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewPassSet, code_requested, pin_confirmed]);


    function onCompletePin(value) {
        setPin(value)
        requirePasswordReset(email, value)
    }


    if (pin_confirmed) {
        return (
            <React.Fragment>
                {alert}
                <Stack className="form-login" spacing={2}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Contraseña *</InputLabel>
                        <OutlinedInput
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            id="password"
                            className='password'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Confirmar Contraseña *</InputLabel>
                        <OutlinedInput
                            onChange={e => validatePassword(e.target.value)}
                            value={confirmedPassword ? confirmedPassword.value : ''}
                            id="confirmedPassword"
                            className='password'
                            color={confirmedPassword ? confirmedPassword.color : ''}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirmar Contraseña"
                        />
                    </FormControl>
                    <Button className="login-btn" variant="contained" onClick={() => HandleResetPassword()}>Guardar</Button>
                </Stack>
            </React.Fragment>
        )
    }


    if (code_requested) {
        return (
            <React.Fragment>
                <div className='confirm-pin' ref={pinContainer}>
                    <h1 className="title" >Digita el pin de confirmacion que te hemos enviado por sms</h1>
                    <PinInput
                        className="pin-input"
                        length={4}
                        initialValue=""
                        onChange={(value, index) => { }}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: '10px' }}
                        inputStyle={{ borderColor: 'white' }}
                        inputFocusStyle={{ borderColor: 'red' }}
                        onComplete={(value, index) => { onCompletePin(value) }}
                        autoSelect={true}
                        regexCriteria={/^[0-9]*$/}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {alert}
            <Stack className="form-login" spacing={2}>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <TextField label="Email" onChange={e => setEmail(e.target.value)} />
                </FormControl>

                <Button className="login-btn" variant="contained" onClick={() => HandleRequestCode()}>Reestablecer contraseña</Button>
            </Stack>
        </React.Fragment>
    )

    function HandleRequestCode() {
        if (email && email !== '' && email !== ' ') {
            setAlert()
            requirePasswordReset(email)
        } else {
            displayAlert('Debes introducir tu correo electrónico')
        }
    }

    function validatePassword(value) {
        if (password === value) {
            setConfirmedPassword({ 'value': value, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        } else {
            displayAlert('Las contraseña no coincide con la confirmación', 'error')
            setConfirmedPassword({ 'value': value, 'valid': false, 'color': 'error' })
            return false
        }

    }

    function HandleResetPassword() {
        if (confirmedPassword?.valid) {
            resetPassword(email, pin, confirmedPassword.value)
        } else {
            setAlert(<Alert severity="error">Las contraseña no coincide con la confirmación</Alert>)
        }
    }

}

export default PasswordReset;