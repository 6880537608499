import '@fontsource/roboto/500.css'
import '../css/login.css'
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'
import { Button } from '@mui/material'
import { Alert, Chip } from '@mui/material'
import { ArrowBack, VisibilityOff, Visibility } from '@mui/icons-material'
import { FormControl, InputLabel, OutlinedInput, CircularProgress } from '@mui/material'
import userRegistration from '../hooks/userRegistration'
import SignUpSteps from './SignUpSteps'
import { Checkbox, InputAdornment, IconButton } from '@mui/material'
import { FormControlLabel, Backdrop, Card } from '@mui/material'
import DoneIcon from '@mui/icons-material/DonutLargeOutlined'
import { terms } from './terms'
import Logo from '../media/icons/logo-blanco.png'


export function SignUpForm({ setRegister, setMessage }) {

    const [name, setUserName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [cedula, setCedula] = useState()
    const [role, setRole] = useState()
    const [company, setCompany] = useState()
    const [position, setPosition] = useState()
    const [password, setPassword] = useState()
    const [confirmedPassword, setConfirmedPassword] = useState()
    const [acceptTerms, setAcceptTerms] = useState()
    const [acceptNotifications, setAcceptNotifications] = useState(false)

    const [alert, setAlert] = useState()
    const [displayTerms, setDisplayTerms] = useState()
    const [showPassword, setShowPassword] = React.useState(false)
    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'Empleador', color: 'default', disabled: false },
        { key: 1, label: 'Empleado', color: 'default', disabled: true },
        { key: 2, label: 'Profesional Independiente', color: 'default', disabled: true },
        { key: 3, label: 'Desempleado', color: 'default', disabled: true },
        { key: 4, label: 'Pensionado', color: 'default', disabled: true },
    ])


    const handleSelection = (data) => () => {
        const chips = chipData
        if (chips[data.key]['color'] === 'primary') {
            chips[data.key]['color'] = 'default'
        } else {
            chips[data.key]['color'] = 'primary'
        }
        let roleSelected = chips.filter((chip) => chip.color === 'primary')
        var roleLabels = []
        roleSelected.forEach(chip => {
            roleLabels.push(
                chip.label
            )
        })
        setRole(roleLabels)
        //setChipData((chips) => chips.filter((chip) => chip.key !== '10'))

    }

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const {
        success,
        message,
        registrateUser,
        isLoading
    } = userRegistration()

    const handleClickTerms = (value) => {
        if (acceptTerms) {
            setDisplayTerms(false)
            setAcceptTerms(false)
        } else {
            setDisplayTerms(true)
        }

    }

    const handleClickNotification = () => {
        if (acceptNotifications) {
            setAcceptNotifications(false)
        } else {
            setAcceptNotifications(true)
        }

    }

    const handleAcceptTerms = () => {
        setAcceptTerms(true)
        setDisplayTerms(false)
    }

    useEffect(() => {
        if (success) {
            setRegister(false)
            setMessage(
                `¡Solicitud enviada correctamente! Una vez que se apruebe tu acceso,
                 recibirás un SMS en tu celular para poder acceder a la plataforma.`
            )
        } else if (message) {
            displayAlert(message)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRegister, success, message, role, acceptTerms, setMessage])

    let PersonalInfo = <React.Fragment>
        <Stack spacing={2} style={{ margin: '10px', overflow: 'hidden' }}>
            <TextField label="Nombre *" value={name} onChange={e => setUserName(e.target.value)} autoComplete="off" />
            <TextField label="Apellido *" value={lastName} onChange={e => setLastName(e.target.value)} />
            <TextField label="Correo Electrónico *" value={email ? email.value : ''} color={email ? email.color : ''} onChange={e => validateEmail(e.target.value)} />
            <TextField label="Número Celular *" color={phone ? phone.color : ''} value={phone ? phone.value : ''} onChange={e => validatePhone(e.target.value)} autoComplete="phone" />
            <TextField label="Número de Cédula *" color={cedula ? cedula.color : ''} value={cedula ? cedula.value : ''} onChange={e => validateCedula(e.target.value)} autoComplete="cedula" />
            <FormControlLabel required control={<Checkbox className='check' onClick={() => handleClickNotification()} />} label="Deseo recibir notificaciones" />
        </Stack>
    </React.Fragment >

    let ProfessionalInfo = <React.Fragment>
        <Stack spacing={2}>
            <TextField label="Empresa" value={company} onChange={e => setCompany(e.target.value)} autoComplete="off" />
            <TextField label="Posición" value={position} onChange={e => setPosition(e.target.value)} autoComplete="company-position" />
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Contraseña *</InputLabel>
                <OutlinedInput
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    id="password"
                    className='password'
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Contraseña"
                    autoComplete="new-password"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Confirmar Contraseña *</InputLabel>
                <OutlinedInput
                    autoComplete="confirm-new-password"
                    onChange={e => validatePassword(e.target.value)}
                    value={confirmedPassword ? confirmedPassword.value : ''}
                    id="confirmedPassword"
                    className='password'
                    color={confirmedPassword ? confirmedPassword.color : ''}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Confirmar Contraseña"
                />
            </FormControl>
            <FormControlLabel required control={<Checkbox className='check' onClick={(e) => handleClickTerms(e)} />} label="Acepto los términos y condiciones *" />
            <Button className="login-btn" variant="contained" onClick={() => register()}>Enviar</Button>
        </Stack>
    </React.Fragment >

    let roleChips = <React.Fragment>
        <Stack style={{ alignItems: 'flex-start', display: 'flex' }}>
            {chipData.map((data) => {
                let icon
                icon = <DoneIcon />
                return (

                    <Chip
                        key={data.key}
                        className='chips'
                        variant="outlined"
                        deleteIcon={icon}
                        label={data.label}
                        color={data.color}
                        onClick={handleSelection(data)}
                        onDelete={handleSelection(data)}
                        disabled={data.disabled}
                        style={{ maxWidth: '400px' }}
                    />

                )
            })}
        </Stack>
    </React.Fragment>

    let steps = [
        {
            'label': 'Datos personales',
            'description': 'Con esta información, LISA podrá darte una atención más personalizada',
            'element': PersonalInfo
        },
        {
            'label': 'Actualmente soy:',
            'description': 'Con esta información, LISA podrá darte una atención más personalizada',
            'element': roleChips
        },
        {
            'label': 'Información profesional',
            'description': 'Con esta información, LISA podrá darte una atención más personalizada',
            'element': ProfessionalInfo
        }
    ]

    const card = <div style={{ padding: '6%' }}>
        <h3>Términos y Condiciones</h3>
        <div style={{ marginTop: '20px', height: '38vh', overflow: 'scroll' }}>
            {terms}
        </div>
        <Button style={{ marginTop: '20px' }} onClick={() => handleAcceptTerms()}>Aceptar</Button>
    </div>

    return (
        <React.Fragment >
            <Backdrop
                sx={{ color: '#fff', zIndex: 500 }}
                open={isLoading}
            >
                <CircularProgress sx={{ color: "white", fontSize: 80 }} />
            </Backdrop>
            <Backdrop
                style={{ zIndex: 10 }}
                open={displayTerms}>
                <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => setDisplayTerms(false)} style={{ zIndex: 10000 }}> </Chip>
                <Card className='terms' style={{ marginTop: '30%', height: '65%', width: '90%' }}
                    variant="outlined">
                    {card}
                </Card>
            </Backdrop>
            {/* <img src={Logo} className='form-logo' alt='AFP crecer' style={{ position: 'relative', maxWidth: '150px', zIndex: 11, mixBlendMode: 'luminosity', float: 'right' }}></img> */}
            <Button className="back-btn" variant="contained" onClick={() => back()}> <ArrowBack /> Salir </Button>
            {alert}
            <div className='form-signup' >
                <SignUpSteps steps={steps} className='step-container' />
            </div>

        </React.Fragment >
    )

    function register() {

        let validatedInputs = (email ? validateEmail(email.value) : false) && (phone ? validatePhone(phone.value) : false) && (cedula ? validateCedula(cedula.value) : false) && (confirmedPassword ? validatePassword(confirmedPassword.value) : false)
        console.log(validatedInputs)
        if (validateInput(name) && validateInput(lastName) && validateInput(password) && validateInput(role) && validateInput(cedula) && validateInput(email.value) && validateInput(phone) && validateInput(acceptTerms) && validateInput(confirmedPassword.value) && validatedInputs) {
            registrateUser(name, lastName, email.value, phone.value, cedula.value, role, company, position, confirmedPassword.value, terms, acceptNotifications)
        }
    }

    function back() {
        setRegister(false)
    }

    function validateCedula(value) {
        // eslint-disable-next-line
        const re = /^[0-9]*$/
        var cedulaNum = re.exec(value)
        try {
            cedulaNum = cedulaNum.join()
        }
        catch {
            cedulaNum = cedulaNum ? cedulaNum[0] : ''
        }

        var validate = cedulaNum.length === 11 ? true : false
        if (cedulaNum.length < 11) {
            displayAlert("El número de cédula debe contener 11 digitos")
            setCedula({ 'value': cedulaNum, 'valid': false, 'color': 'error' })
            return false
        } else if (validate) {
            setCedula({ 'value': cedulaNum, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        } else if (cedulaNum.length > 11) {
            setCedula({ 'value': cedula.value, 'valid': true, 'color': 'success' })
        }

    }

    function validateEmail(email) {

        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var validate = re.test(email)
        if (!validate) {
            displayAlert("No es una dirección de email válida ejemplo: ejemplo@ejemplo.com")
            setEmail({ 'value': email, 'valid': false, 'color': 'error' })
            return false
        } else {
            setEmail({ 'value': email, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        }

    }

    function validatePhone(value) {

        // eslint-disable-next-line
        const re = /^[0-9]*$/
        var phoneNum = re.exec(value)
        try {
            phoneNum = phoneNum.join()
        }
        catch {
            phoneNum = phoneNum ? phoneNum[0] : ''
        }

        var validate = phoneNum.length === 10 ? true : false
        if (phoneNum.length < 10) {
            displayAlert("El número telefónico debe contener 10 digitos")
            setPhone({ 'value': phoneNum, 'valid': false, 'color': 'error' })
            return false
        } else if (validate) {
            setPhone({ 'value': phoneNum, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        } else if (phoneNum.length > 10) {
            setPhone({ 'value': phone.value, 'valid': true, 'color': 'success' })
        }


    }

    function validatePassword(value) {
        if (password === value) {
            setConfirmedPassword({ 'value': value, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        } else {
            displayAlert("Deben coincidir los valores en la contraseña")
            setConfirmedPassword({ 'value': value, 'valid': false, 'color': 'error' })
            return false
        }

    }

    function validateInput(value) {
        if (value !== '' && value !== null && value !== undefined && value !== false) {
            return true
        }
        displayAlert('Los campos con * son requeridos')
        return false
    }

    function displayAlert(message) {
        if (!alert) {
            setAlert(<Alert className='alert' severity="error">{message}</Alert>)
            setTimeout(() => {
                setAlert()
            }, 3000)
        }
    }
}



