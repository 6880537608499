import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'

const data = [
    {
        'title': '¿De qué se trata Lisa?',
        'content': `Nuestra asistente virtual, Lisa, es una creación de la empresa AFP Crecer en República Dominicana. 
        Diseñada con tecnología de inteligencia artificial, Lisa es amigable, servicial y está disponible las 24 horas del día,
        los 7 días de la semana. Con una capacidad veloz y eficiente, puede comprender y procesar rápidamente información, 
        proporcionando respuestas precisas y útiles en tiempo real. Además de ofrecer información sobre nuestros servicios y 
        planes de pensiones, Lisa está programada para manejar grandes volúmenes de preguntas, atender a múltiples usuarios sin 
        perder calidad en sus respuestas y transmitir confianza a través de un lenguaje claro y sencillo, brindando a los usuarios 
        seguridad en la información que reciben.`
    },
    {
        'title': '¿Lisa da respuestas precisas y seguras?',
        'content': `Lisa es un proyecto experimental en constante mejora. Aunque se esfuerza por brindar respuestas precisas, siempre verifique la información proporcionada. Cientos de evaluadores han contribuido a su desarrollo antes de su lanzamiento público, garantizando calidad y seguridad. Si bien Lisa tiene controles de seguridad, tenga en cuenta que ocasionalmente puede mostrar información inexacta.`
    },
    {
        'title': '¿Puede Lisa explicar cómo funciona?',
        'content': `Sí, Lisa puede explicar por sí misma sus principales funciones.`
    },
    {
        'title': '¿Por qué Lisa no sabe lo que dije antes en una conversación?',
        'content': `De momento, Lisa sólo puede recordar lo que ha dicho mientras está teniendo una conversión,
         pero una vez finalizada la conversación no tiene memoria sobre conversaciones pasadas.`
    },
    {
        'title': '¿Cómo puedo ayudar a que Lisa sea mejor?',
        'content': `Puedes ayudar a que Lisa sea mejor calificando siempre sus respuestas.`
    },
    {
        'title': '¿Quién puede usar Lisa?',
        'content': `De momento, Lisa Beta 2 es una versión prueba, sólo para usuarios registrados y previa aprobación de AFP Crecer.`
    },
    {
        'title': '¿Cuántos idiomas habla Lisa?',
        'content': `Lisa está diseñada para hablar tanto en español como en inglés, lo que permite una experiencia interactiva y fluida en ambos idiomas. Estamos comprometidos en brindarte una herramienta versátil y accesible para satisfacer tus necesidades de comunicación.`
    },
    {
        'title': '¿Qué términos de servicio se aplican a mi uso de Lisa?',
        'content': `Se aplican los Términos de Servicio de la página web de AFP Crecer, y los Términos Ampliados`
    },
    {
        'title': '¿Qué datos se recopilan? ¿Cómo se usa?',
        'content': `Lisa recopila únicamente los datos.`
    },
    {
        'title': '¿Quién tiene acceso a mis conversaciones de Lisa?',
        'content': `La privacidad es nuestra máxima prioridad, por lo que nunca vendemos su información personal a terceros. Para mejorar a Lisa y garantizar su privacidad, utilizamos un enfoque cuidadoso. Seleccionamos un conjunto de conversaciones de muestra y empleamos herramientas automatizadas para eliminar cualquier información de identificación personal. Estas muestras son revisadas por revisores capacitados y su privacidad está protegida mientras Lisa sigue mejorando.`
    },
    {
        'title': '¿Puedo eliminar mis datos de mi cuenta de AFP Crecer?',
        'content': `Sí, en caso de que desees eliminar tus datos puedes escribir a innovcion@afpcrecer.com.do para solicitarlo.`
    },
    {
        'title': '¿Cómo puedo ayudar a que Lisa sea mejor?',
        'content': `Puedes ayudar a que Lisa sea mejor calificando siempre sus respuestas.`
    },

]

export function Faqs() {
    return (
        <React.Fragment>
            <div style={{
                color: '#585858',
                padding: '10px 10px',
                backgroundColor: 'rgb(249 249 249)',
                minHeight: '100vh',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography sx={{ margin: '60px 0px 20px 0px', fontFamily: 'Gilroy-Light' }}><h1>{'Preguntas Frecuentes'}</h1></Typography>
                {data.map((item) => {
                    return (
                        <Accordion sx={{
                            maxWidth: '800px',
                            width: '100%'
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ color: '#585858', fontFamily: 'Gilroy-Light' }}>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={{ color: '#838383', fontFamily: 'Gilroy-Light' }}>
                                    {item.content}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>

        </React.Fragment>
    )

}

