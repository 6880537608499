import React, { useEffect, useState } from "react";
import { TextField, Fab, FormControlLabel } from "@mui/material";
import { FormControl, FormGroup, Switch } from "@mui/material";
import { Save } from "@mui/icons-material";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import useGetUserInfo from "../hooks/useGetUserInfo";
import '../css/accountSettings.css'

export function AccountSettings(auth_token) {
    const [name, setName] = useState('')
    const [cedula, setCedula] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [position, setPosition] = useState('')
    const [role, setRole] = useState('')
    const [email_notification, setEmailNotification] = useState(false)
    const [sms_notification, setSmsNotification] = useState(false)
    const [push_notification, setPushNotification] = useState(false)


    const {
        getUserInfo,
        user_info,
        updateUserInfo,
        error,
        success,
        isLoading
    } = useGetUserInfo()

    function handleSave() {
        let info = {
            name: name,
            last_name: lastName,
            email: email,
            cedula: cedula,
            phone: phone,
            company: company,
            position: position,
            role: [role],
            email_notification: email_notification,
            sms_notification: sms_notification,
            push_notification: push_notification
        }
        updateUserInfo(auth_token.auth_token, info)
    }
    useEffect(() => {
        if (!user_info) {
            getUserInfo(auth_token.auth_token)
        } else {
            setName(user_info.name)
            setLastName(user_info.last_name)
            setCedula(user_info.cedula)
            setEmail(user_info.email)
            setPhone(user_info.phone)
            setCompany(user_info.company)
            setPosition(user_info.position)
            setRole(user_info.role?.[0])
            setEmailNotification(user_info.email_notification)
            setSmsNotification(user_info.sms_notification)
            setPushNotification(user_info.push_notification)
        }
    }, [user_info])

    return (
        <React.Fragment>
            <div className="account-settings">
                <div className="avatar-name">
                    <Avatar style={{ height: '150px', width: '150px', marginBottom: '20px', }}  {...stringAvatar(user_info?.name + ' ' + user_info?.last_name)} />
                    <p>{user_info?.name} {user_info?.last_name}</p>
                </div>
                <Stack direction="col" className="settings-form" spacing={2}>
                    <label>Informacion Personal</label>
                    <FormGroup>
                        <label>Nombre:</label>
                        <TextField value={name} autoComplete="off" onChange={(e) => setName(e.currentTarget.value)} />
                        <label>Apellido:</label>
                        <TextField value={lastName} autoComplete="off" onChange={(e) => setLastName(e.currentTarget.value)} />
                        <label>Cedula:</label>
                        <TextField value={cedula} autoComplete="off" onChange={(e) => setCedula(e.currentTarget.value)} />
                        <label>Email:</label>
                        <TextField value={email} autoComplete="off" onChange={(e) => setEmail(e.currentTarget.value)} />
                        <label>Telefono:</label>
                        <TextField value={phone} autoComplete="off" onChange={(e) => setPhone(e.currentTarget.value)} />
                        <label>Empresa:</label>
                        <TextField value={company} autoComplete="off" onChange={(e) => setCompany(e.currentTarget.value)} />
                        <label>Posicion:</label>
                        <TextField value={position} autoComplete="off" onChange={(e) => setPosition(e.currentTarget.value)} />
                        <label>Actualmente soy:</label>
                        <TextField value={user_info?.role} autoComplete="off" disabled />


                    </FormGroup>

                    <label>Notificaciones</label>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={email_notification}
                                onChange={(e) => setEmailNotification(e.currentTarget.checked)} />}
                            label="Notificaciones de Email" />
                        <FormControlLabel
                            control={<Switch checked={sms_notification}
                                onChange={(e) => setSmsNotification(e.currentTarget.checked)} />}
                            label="Notificaiones de SMS" />
                        <FormControlLabel
                            control={<Switch
                                checked={push_notification}
                                onChange={(e) => setPushNotification(e.currentTarget.checked)} />}
                            label="Notificaiones Push" />
                    </FormGroup>
                </Stack>

                <Fab variant="extended" color="primary" aria-label="add" onClick={() => handleSave()} className="save-button">
                    <Save sx={{ mr: 1 }} />
                    Guardar Cambios
                </Fab>
            </div>
        </React.Fragment>
    )
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
