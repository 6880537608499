import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/password-reset/'
const PR_INFO = {
  success: false,
  isNewPassSet: false,
}

/**
 * Reset Password
 */
export default function ResetPassword() {
  const [prInfo, setPrInfo] = useState(PR_INFO)

  const resetPassword = async (email, code, password) => {
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, code: code, password: password })
    };
    setPrInfo({ ...PR_INFO, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setPrInfo({
            success: true,
            isNewPassSet: true,
          })
        }
      })
      .catch(error => {

        setPrInfo(c => ({ ...c, error }))
      })
  }
  return { ...prInfo, resetPassword }
}
