import React from 'react'
import { Card, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'

const data = [
    {
        'title': '2023.06.01',
        'content': <div>
            <br></br>
            -Expansión de Contenido:<br></br><br></br> Buscamos enriquecer la experiencia del usuario mediante la adición de nuevos contenidos educativos y útiles. Estos contenidos se centrarán en temas de seguridad social, desarrollo profesional, gestión y productividad. Los usuarios tendrán la oportunidad de profundizar su conocimiento en estas áreas, y podrán acceder a estos nuevos recursos fácilmente a través de Lisa.<br></br><br></br>


            -Inteligencia Artificial Generativa: <br></br><br></br>Como parte de nuestra mejora en la interacción con el usuario, hemos implementado la inteligencia artificial generativa para responder a las consultas de los usuarios. Esta IA analiza las preguntas o los casos planteados y genera respuestas pertinentes y precisas. Esto no sólo mejora la eficiencia de nuestra plataforma, sino que también asegura que las respuestas sean útiles y relevantes para los usuarios.<br></br><br></br>


            -Consultas por Voz:<br></br><br></br>En un esfuerzo por hacer nuestra plataforma más accesible y fácil de usar, ahora aceptamos consultas por voz. Los usuarios pueden simplemente hablar sus preguntas en lugar de tener que escribirlas. Esta funcionalidad no sólo mejora la accesibilidad para aquellos que tienen dificultades con la escritura, sino que también permite una interacción más rápida y cómoda para todos nuestros usuarios.<br></br><br></br>


            -Función de Exportación de Respuestas: <br></br><br></br>Para facilitar aún más la interacción con nuestros usuarios, hemos añadido la capacidad de exportar el texto de las respuestas de Lisa por correo electrónico y WhatsApp. Esta función permitirá a los usuarios guardar y revisar las respuestas en su propio tiempo, así como compartirlas fácilmente con otros. Independientemente de si prefieres revisar la información en tu correo electrónico o en tu aplicación de WhatsApp, nos hemos asegurado de que las respuestas estén disponibles para ti en la plataforma que elijas.<br></br><br></br>


            -Asistencia Humana a través de Videollamada: <br></br><br></br>Entendemos que puede haber momentos en los que necesites hablar con una persona real. Por eso, hemos añadido la posibilidad de interactuar con un agente humano a través de videollamadas. Si la Lisa no tiene una respuesta a tu consulta, o si simplemente quieres confirmar la información proporcionada por ella, puedes solicitar una videollamada con uno de nuestros agentes. Esta función garantiza que siempre tendrás acceso a la ayuda que necesitas, y que nunca te sentirás frustrado o confundido por la falta de respuestas.</div>
    },
]

export function Updates() {
    return (
        <React.Fragment>
            <div style={{
                color: '#585858',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: '30px 20px',
                backgroundColor: 'rgb(249 249 249)',
                minHeight: '100vh',
            }}>
                <Typography sx={{ margin: '70px 0px 20px 0px', fontFamily: 'Gilroy-Light' }}><h1>{'Actualizaciones'}</h1></Typography>
                <Stack spacing={5}>
                    {data.map((item) => {
                        return (
                            <Card sx={{ color: '#585858', padding: '20px', borderRadius: '15px', borderWidth: '0px', boxShadow: 'none' }}>
                                <Typography sx={{ fontSize: '25px', fontFamily: 'Gilroy-Light' }}>{item.title}</Typography>
                                <Typography sx={{ color: '#838383', fontFamily: 'Gilroy-Light' }}>
                                    {item.content}
                                </Typography>
                            </Card>
                        )
                    })}
                </Stack>
            </div>

        </React.Fragment>
    )

}

