import * as React from 'react'
import { useRef, useState} from 'react'
import Popover from '@mui/material/Popover'
import { Anchor, MicSharp, Phone} from '@mui/icons-material'
import '../css/iconsBar.css'

import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,

} from "react-share";


function IconsBar({ shareElement, setShare, switchMode }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickPhone = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClosePhone = () => {
      setAnchorEl(null);
    };


    const handleClose = () => {
        setShare(false);
    };

    React.useEffect(() => {
    }, [shareElement, anchorEl])

    const open = Boolean(shareElement);
    const id = open ? 'simple-popover' : undefined;
    const openPhone = Boolean(anchorEl);
    const idPhone = openPhone ? 'simple-popover' : undefined;
    const message = 'Conversa con Lisa en:';
    var shareUrl = 'https://afpcrecer.com.do/lisa'



    return (
        <React.Fragment>

            <Popover
                id={id}
                open={open}
                anchorEl={shareElement}
                onClose={handleClose}
                className='share-container'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <FacebookShareButton quote={message} url={shareUrl}>
                    <FacebookIcon round={true} />
                </FacebookShareButton>

                <EmailShareButton subject='Lisa - Afp Crecer' body={message} url={shareUrl}>
                    <EmailIcon className='share-icon' round={true} />
                </EmailShareButton>

                <WhatsappShareButton title={message} url={shareUrl}>
                    <WhatsappIcon className='share-icon' round={true} />
                </WhatsappShareButton>

                <LinkedinShareButton title={message} url={shareUrl}>
                    <LinkedinIcon className='share-icon' round={true} />
                </LinkedinShareButton>
            </Popover>

            <div ><a href='tel:8093312727,2' ><Phone  className='test-icon' /></a></div>
            <Popover
                id={idPhone}
                open={openPhone}
                anchorEl={anchorEl}
                onClose={handleClosePhone}
                className='share-container'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

            >
                <b>Asistencia vial</b>
                <hr/>
                <a href='tel:8093818830' ><Phone /> 809-381-8830 </a>
                <hr/>
                <b>Atención al cliente</b>
                <hr/>
                <a href='tel:8099205000' ><Phone /> 809-920-5000 </a>
                <hr/>
                <b>Líneas de pagos</b>
                <hr/>
                <a href='tel:8099205048' ><Phone /> 809-920-5048 </a>
                <hr/>
                <a href='tel:8099205065' ><Phone /> 809-920-5065 </a>
                <hr/>
                <b>Unidad legal</b>
                <hr/>
                <a href='tel:8094860976' ><Phone /> 809-486-0976 </a>

            </Popover>

        </React.Fragment>
    );
}

export default IconsBar